<template>
  <div class="scheme-contain">
    <div class="affixion-contain">
      <Form :label-width="115">
        <Row>
          <Col span="8" offset="8">
            <Form-item label="运动日期：">
              <Date-picker type="date" :options="options" v-model="test_date" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
            </Form-item>
            <!-- <Form-item label="运动时间：">
              <Time-picker type="time" v-model="test_time" placeholder="选择时间" @on-change="handleChangeTime"></Time-picker>
            </Form-item> -->
            <Form-item label="总消耗：">
              <Row>
                <Col :span="22"><Input v-model="sportsData.total" placeholder="请输入总消耗量"></Input></Col>
                <Col :span="2">&nbsp;&nbsp;kcal</Col>
              </Row>
            </Form-item>
            <Form-item label="总有效消耗量：">
              <Row>
                <Col :span="22"><Input v-model="sportsData.total_effective" placeholder="请输入总有效消耗量"></Input></Col>
                <Col :span="2">&nbsp;&nbsp;kcal</Col>
              </Row>
            </Form-item>
            <Form-item label="总运动步数：">
              <Row>
                <Col :span="22"><Input v-model="sportsData.total_step" placeholder="请输入总运动步数"></Input></Col>
                <Col :span="2">&nbsp;&nbsp;步</Col>
              </Row>
            </Form-item>
            <Form-item label="总运动时长：">
              <Row>
                <Col :span="22"><Input v-model="sportsData.total_time" placeholder="请输入总运动时长"></Input></Col>
                <Col :span="2">&nbsp;&nbsp;min</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			sportsData: {
				test_date: '',
				// test_time: '',
				total: '',
				total_effective: '',
				total_step: '',
				total_time: '',
			},
			test_date: '',
			power: [],
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
			// test_time: ''
		};
	},
	computed: {
		sportId() {
			return this.$route.params.sport_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	methods: {
		handleInit() {
			let arr = [];
			MemberService.sportsDetail({ id: this.sportId }).then((data) => {
				arr = data.create_time.split(' ');
				this.test_date = this.sportsData.test_date = arr[0];
				// this.test_time = this.sportsData.test_time = arr[1]
				this.sportsData.total = data.total;
				this.sportsData.total_effective = data.total_effective;
				this.sportsData.total_step = data.total_step;
				this.sportsData.total_time = data.total_time;
			});
		},
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.sportsData.id = this.sportId;
				MemberService.sportsUpdate(this.sportsData).then(() => {
					this.$router.go(-1);
				});
			}
		},
		handleChangeDate(value) {
			this.sportsData.test_date = value;
		},
		// handleChangeTime (value) {
		//   this.sportsData.test_time = value
		// },
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.test_date) {
				this.$Message.warning('请输入日期');
				return false;
			} else if (this.sportsData.total === '') {
				this.$Message.warning('请输入总消耗量');
				return false;
			} else if (!this.isPositiveNumber(this.sportsData.total)) {
				this.$Message.warning('请输入有效总消耗量');
				return false;
			} else if (this.sportsData.total_effective === '') {
				this.$Message.warning('请输入总有效消耗量');
				return false;
			} else if (!this.isPositiveNumber(this.sportsData.total_effective)) {
				this.$Message.warning('请输入有效总有效消耗量');
				return false;
			} else if (this.sportsData.total_step === '') {
				this.$Message.warning('请输入总运动步数');
				return false;
			} else if (!this.isPositiveNumber(this.sportsData.total_step)) {
				this.$Message.warning('请输入有效总运动步数');
				return false;
			} else if (this.sportsData.total_time === '') {
				this.$Message.warning('请输入总运动时长');
				return false;
			} else if (!this.isPositiveNumber(this.sportsData.total_time)) {
				this.$Message.warning('请输入有效总运动时长');
				return false;
			} else {
				return true;
			}
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
